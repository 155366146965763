<template>
  <div class="tracking-table">
    <!-- Loading State -->
    <div v-if="isLoading">
      <div v-for="n in loadingCount" :key="n" class="tracking-table--row">
        <div class="tracking-table--indicator">
          <span class="indicator--dot"></span>
          <span class="indicator--line"></span>
        </div>
        <div class="tracking-table--content">
          <div class="skeleton"></div>
          <div class="skeleton w-50 mt-1"></div>
          <div class="skeleton w-50 mt-1"></div>
        </div>
      </div>
    </div>
    <!-- Component Content -->
    <div v-else>
      <div
        v-for="(row, index) in data"
        :key="index"
        class="tracking-table--row"
        :class="{ active: setActive(row, index) }"
      >
        <div class="tracking-table--indicator">
          <span class="indicator--dot"></span>
          <span class="indicator--line"></span>
        </div>
        <div class="tracking-table--content">
          <slot name="content" :row="row">{{ row.content }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    setActive: {
      type: Function,
      default: (row, index) => {
        return index == 0;
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    loadingCount: {
      type: Number,
      default: 1
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.tracking-table {
  .tracking-table--row {
    display: flex;
    &.active {
      .tracking-table--content {
        background: linear-gradient(
          to right,
          lighten($color-main, 48),
          lighten($color-main, 45)
        );
        background-position: 100%;
      }
      .indicator--dot {
        border: solid 8px $color-main;
      }
    }
  }
  .tracking-table--indicator {
    margin-left: 8px;
    margin-right: 16px;
    .indicator--dot {
      transition: all 0.3s;
      display: block;
      border: solid 2px $color-main;
      border-radius: 50%;
      background: white;
      height: 24px;
      width: 24px;
    }
    .indicator--line {
      display: block;
      width: 4px;
      height: 100%;
      margin: auto;
      background: $color-main;
    }
  }
  .tracking-table--content {
    padding: 8px 16px;
    margin-bottom: 12px;
    border-radius: 5px;
    width: 100%;
  }

  .tracking-table--row:last-child {
    .indicator--line {
      display: none;
    }
  }
}
</style>
