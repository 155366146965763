<template>
  <div class="project-sale-tracking-log">
    <!-- Tracking Table -->
    <div class="progress card p-2 mt-5">
      <h4 class="mb-3">Progress</h4>
      <tracking-table
        :data="trackingLog"
        :setActive="(row, index) => index == 0"
      >
        <template #content="{ row }">
          <h5>{{ row.title }}</h5>
          <p>{{ row.message }}</p>
          <div class="progress--date mt-1">
            {{ $moment(row.updatedAt).format("YYYY-MM-DD hh:mm:A") }}
          </div>
        </template>
      </tracking-table>
    </div>
  </div>
</template>

<script>
import TrackingTable from "@/components/GlobalComponents/TrackingTable";

export default {
  components: {
    TrackingTable
  },
  mixins: [],
  props: {
    trackingLog: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-sale-tracking-log {
  .progress {
    .progress--date {
      color: #7b7b7b;
      font-size: 12px;
    }
  }
}
</style>
